<template>
  <div>CGU</div>
</template>

<script>
export default {
};
</script>

<style>

</style>
